<template>
  <div class="chatindex">
      <div class="mainbox base_w">
          <div class="tabsbox flexbox"> 
            <p @click.stop="change(i,item.id)" :class="[tabItem == i ? 'active' : '']" 
            v-for="(item,i) in list" :key="i">{{ item.name }}</p>
          </div>
          <div class="textbox">
              <div class="textline" v-for="(item,i) in problemlist" :key="i" @click.stop="tourl('/helpingDetail?id=' + item.id)">
                {{ item.title }}
              </div>
             <NoData v-if="problemlist.length <= 0"></NoData>
          </div>
      </div>
  </div>
</template>

<script>
import apiUrl from '@/api/main'
import NoData from '@/components/NoData'

export default {
  components: { NoData },
  data() {
      return {
        tabItem: 0, 
        list: [],
        typeId:'',
        problemlist:[]
      };
  },
  filters:{ },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getlist();
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
    change(num,id){
      this.tabItem = num;
      this.typeId = id;
      this.getProblem();
    },
    tourl(url){
      this.$router.push({
          path: url
      })
    },
    // 获取问题类型
    getlist() {
      if(!localStorage.getItem('userId')){
        return;
      }
      let data = {
        type:'用户端'
      }
      apiUrl.lsProblemTypelist(data).then((res) => {
        this.list = res.rows;
        if(res.rows.length==0)return;
        this.typeId = this.list[0].id
        this.getProblem();
      })
    },
    getProblem(){
      let data = {
        typeId:  this.typeId
      }
      apiUrl.lsProblemlist(data).then((res) => {
        this.problemlist = res.rows;
      })
    }
   


  },
 
  }
</script>
<style lang='scss' scoped>
.chatindex{
  background-color:  #F8F8F8;
  padding: 20px 0 33px;
  .mainbox{
    // height: 650px;
    // overflow: auto;
    background: #FFFFFF;
    border-radius: 13px;
    padding: 17px 0 84px;
    .tabsbox{
      height: 40px;
      line-height: 41px;
      border-bottom: 1px solid  #ECECEC;
      padding-left: 67px;
      p{
        font-size: 15px;
        color: #333333;
        margin-right: 53px;
        cursor: pointer;
      }
      .active{
          font-weight: 600;
          color: #305BFE;
          border-bottom: 3px solid #305BFE;
      }
    }
    .textbox{
      .textline{
        border-bottom: 1px solid #ECECEC;
        height: 60px;
        line-height: 60px;
        padding: 0 27px;
        cursor: pointer;
      }
      .textline:hover{
        color: #305BFE;
      }
    }
  }

 
  
}
</style>